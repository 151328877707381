import React from 'react';
import { List } from '../component/campaign/List';
import { Add } from '../component/campaign/Add';
import { View } from '../component/campaign/View';

export default function Campaing({ tokens, cid, campaigns, setCampaigns }) {
  switch (cid.toString().toLowerCase()) {
    case 'list':
      return (
        <List
          tokens={tokens}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
        />
      );
    case 'add':
      return (
        <Add
          tokens={tokens}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
        />
      );
    default:
      const campaign = campaigns.find((c) => c.cid === cid);
      return (
        <View
          tokens={tokens}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
          campaign={campaign}
        />
      );
  }
}
