import { Box, Avatar, Text, Tabs, Tab } from 'grommet';
import { useEffect, useState } from 'react';
import Facebook from '../Facebook';
import Orb from '../component/Orb';
import { debug, log } from '../Util';
import Overview from '../component/instagram/Overview';
import PostComments from '../component/instagram/PostComments';
import PostTags from '../component/instagram/PostTags';
import React from 'react';
import dayjs from 'dayjs';

const INIT_FIELDS =
  'name,biography,followers_count,follows_count,ig_id,media_count,profile_picture_url,website';

const MEDIA_FIELDS =
  'comments_count,like_count,shortcode,id,timestamp,media_type';

const INSIGHT_FIELDS =
  'impressions,reach,profile_views,follower_count,website_clicks';

const ACTIVE_FIELDS = 'online_followers';

function Instagram({ tokens, pid, user, logout }) {
  debug(pid, user);
  const [img, setImg] = useState();
  const [totalFollowers, setTotalFollowers] = useState();
  const [totalFollowing, setTotalFollowing] = useState();
  const [totalMedia, setTotalMedia] = useState();
  const [mediaData, setMediaData] = useState();
  const [insightOverview, setInsightOverview] = useState();
  const [activeOverview, setActiveOverview] = useState();
  const token = tokens.find((t) => t.pid === pid);

  useEffect(() => {
    if (!token) return;

    const activityStart = dayjs().subtract(1, 'day').endOf('day');

    Promise.all([
      Facebook.getIgFields(token?.value, `/${token?.pid}`, INIT_FIELDS)
        .then((data) => {
          const [
            {
              followers_count,
              follows_count,
              // ig_id,
              media_count,
              // name,
              profile_picture_url,
            },
          ] = data;
          log('profile data', data);
          setImg(profile_picture_url);
          setTotalFollowers(followers_count);
          setTotalFollowing(follows_count);
          setTotalMedia(media_count);
        })
        .catch((e) => logout('Could not authenticate to Facebook.')),
      Facebook.getIgFields(
        token?.value,
        `/${token?.pid}/media`,
        MEDIA_FIELDS,
        10000,
        true
      ).then((res) => {
        log('media', res);
        setMediaData(res);
      }),
      Facebook.getIgFields(
        token?.value,
        `/${token?.pid}/insights`,
        {
          metric: INSIGHT_FIELDS,
          period: 'day',
          since: dayjs().subtract(30, 'days').unix(),
          until: dayjs().unix(),
        },
        INSIGHT_FIELDS.split(',').length || 0
      ).then((res) => {
        log('insights', res);
        setInsightOverview(res);
      }),
      Facebook.getIgFields(
        token?.value,
        `/${token?.pid}/insights`,
        {
          metric: ACTIVE_FIELDS,
          period: 'lifetime',
          since: activityStart.subtract(7, 'days').unix(),
          until: activityStart.unix(),
        },
        ACTIVE_FIELDS.split(',').length || 0
      ).then((res) => {
        log('active', res);
        setActiveOverview(res);
      }),
    ]);
  }, []);

  const profile = () => (
    <Box direction="column" align="center" justify="center" flex>
      <Avatar src={img} size="xlarge" />
      <Text textAlign="start">{token?.display}</Text>
    </Box>
  );

  return (
    <Box direction="column" flex>
      <Box direction="row" pad="large" gap="xlarge">
        {profile()}
        <Orb day={0} month={0} total={totalFollowers} metric="Followers" />
        <Orb day={0} month={0} total={totalFollowing} metric="Following" />
        <Orb day={0} month={0} total={totalMedia} metric="Posts" />
      </Box>
      <Box flex fill>
        <Tabs flex>
          <Tab title="Overview">
            <Overview
              mediaData={mediaData}
              totalFollowers={totalFollowers}
              insightOverview={insightOverview}
              activeOverview={activeOverview}
            />
          </Tab>
          <Tab title="Post Comments">
            <PostComments
              token={token}
              mediaData={mediaData}
              totalFollowers={totalFollowers}
            />
          </Tab>
          <Tab title="Post Tags">
            <PostTags token={token} />
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
}

export default Instagram;
