import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Box,
  Button,
  CardHeader,
  Stack,
  Layer,
  Heading,
  Paragraph,
  Anchor,
  TextInput,
  Text,
  Tabs,
  Tab,
} from 'grommet';
import { useAPI } from '../component/Firebase';
import { error } from '../Util';

export default function Admin() {
  const API = useAPI();

  const card = (content) => {
    return (
      <Box pad="small" background="light-1" width="medium">
        {content}
      </Box>
    );
  };

  const BetaTab = () => {
    const [note, setNote] = useState('');
    const [key, setKey] = useState('');

    return (
      <Tab title="Beta keys">
        <Box direction="row" gap="small">
          {card(
            <Box gap="small">
              <TextInput
                placeholder="Custom key note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Button
                label="Generate New"
                onClick={async () => {
                  try {
                    const { data } = await API.generateCode({ note });
                    const { key: _key } = data;
                    setKey(_key);
                    setNote('');
                  } catch (e) {
                    error(e);
                  }
                }}
              />
              {key && <TextInput value={key} />}
            </Box>
          )}
        </Box>
      </Tab>
    );
  };

  return <Tabs>{BetaTab()}</Tabs>;
}
