import React, { useState, useEffect, createContext } from 'react';
import theme from './Theme';
import { Box, Grommet, Main } from 'grommet';
import Header from './component/Header';
import Footer from './component/Footer';
import { PATH, error, log, normalize, getUserDoc } from './Util';
import { Link, Route, Switch, useLocation } from 'wouter';

import Login from './page/Login';
import Home from './page/Home';
import Add from './page/Add';
import Instagram from './page/Instagram';
import Campaign from './page/Campaign';
import Admin from './page/Admin';
import { clear, set } from './Storage';
import Spinner from './component/Spinner';
import Feedback from './component/Feedback';
import { useAPI, useFeatureFlags, useFirebase } from './component/Firebase';
import BetaRequest from './component/BetaRequest';

function App() {
  const firebase = useFirebase();
  const API = useAPI();
  const flags = useFeatureFlags();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [location, setLocation] = useLocation();
  const [displayError, setDisplayError] = useState();
  const userDoc = getUserDoc();
  const [showFeedback, setShowFeedback] = useState(false);
  const FeedbackContext = createContext({ showFeedback, setShowFeedback });
  const [campaigns, setCampaigns] = useState([
    { title: 'test campaign 1', cid: '1234567', active: true },
    { title: 'test campaign 2', cid: '2334566', active: false },
    { title: 'test campaign 3', cid: '3434466', active: true },
    { title: 'test campaign 4', cid: '3444466', active: true },
    { title: 'test campaign 5', cid: '3444266', active: false },
    { title: 'test campaign 6', cid: '3441466', active: true },
  ]);

  useEffect(() => {
    if (!user?.tokens) return;
    setTokens(Object.values(user?.tokens || {}));
  }, [user, setTokens]);

  // On page load, set auth listener to track auth state
  useEffect(() => {
    async function createUser() {
      const doc = await API.createUser();
      log('doc', doc);
      if (!doc) return;

      set('userDoc', JSON.stringify(doc));
      return doc;
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(
      async (userState) => {
        log('onAuthStateChanged', userState, userDoc);
        if (!userState || userState === null) {
          clear();
          setUser(null);
          setLocation(PATH.Login);
          setLoading(false);
          return;
        }

        // first time auth check
        if (
          userState.metadata.creationTime ===
            userState.metadata.lastSignInTime &&
          !userDoc
        ) {
          setUser(await createUser());
        } else {
          // not first time auth, but no user record exists
          const doc = (await API.getUser()).data();
          log('user', doc, firebase.auth().currentUser);
          if (!doc) {
            setUser(await createUser());
          } else {
            // User is authenticated, set props.
            setUser(doc);
          }
        }

        setLoading(false);
      },
      (err) => {
        log('auth err', err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  // Redirect auth users from login to auth
  useEffect(() => {
    if (!user) return;

    // Redirect logged in users to the homepage.
    if (location === PATH.Login) {
      setLocation(PATH.Home);
    }
  }, [location, setLocation, user]);

  const RequestBetaOverlay = () => {
    return (
      <Box align="center" justify="center" flex>
        <BetaRequest
          user={user}
          updateUser={(betaUpdate) => {
            setUser((oldUser) => ({ ...oldUser, beta: betaUpdate }));
          }}
        />
      </Box>
    );
  };

  const body = () => {
    if (loading) return <Spinner />;
    if (user && !user.admin && (!user.beta || user.beta === 'request')) {
      return RequestBetaOverlay();
    }

    const getCampaigns = () => {
      if (!flags.enable_campaigns) return <></>;

      return (
        <Route path={normalize(`${PATH.Campaign}/:cid`)}>
          {({ cid }) => (
            <Campaign
              loading={loading}
              tokens={tokens}
              campaigns={campaigns}
              setCampaigns={setCampaigns}
              cid={cid}
            />
          )}
        </Route>
      );
    };

    return (
      <Box align="center" justify="center" flex>
        {displayError && (
          <Box
            align="center"
            justify="center"
            background="status-error"
            pad="small"
            round
            margin={{ bottom: 'small' }}
          >
            {displayError}
          </Box>
        )}
        <Switch>
          <Route path={PATH.Settings}>Settings Page!</Route>
          <Route path={PATH.Admin}>
            <Admin />
          </Route>
          <Route path={PATH.Add}>
            <Add tokens={tokens} setTokens={setTokens} />
          </Route>
          <Route path={normalize(`${PATH.Instagram}/:pid`)}>
            {({ pid }) => (
              <Instagram
                tokens={tokens}
                pid={pid}
                user={user}
                logout={async (e) => {
                  try {
                    setDisplayError(e);
                    return setLocation(PATH.Login);
                  } catch (e) {
                    error('instagram logout error', e);
                  }
                }}
              />
            )}
          </Route>
          <Route path={PATH.Home}>
            <Home
              loading={loading}
              tokens={tokens}
              setTokens={setTokens}
              campaigns={campaigns}
            />
          </Route>
          <Route path={PATH.Login}>
            <Login />
          </Route>
          {getCampaigns()}
          <Route>
            Page not found! Go back to <Link href={PATH.Home}>Home</Link>
          </Route>
        </Switch>
      </Box>
    );
  };

  const getAnnouncement = () => {
    if (!flags.announcement) return <></>;
    return (
      <Box
        align="center"
        justify="center"
        background="status-warning"
        pad="xsmall"
      >
        {flags.announcement}
      </Box>
    );
  };

  return (
    <Grommet theme={theme} full>
      <Box flex fill>
        <Header
          user={user}
          onLogout={() => {
            setUser();
          }}
        />
        {getAnnouncement()}
        <Main direction="row" flex fill>
          {body()}
        </Main>
        <FeedbackContext.Provider value={{ showFeedback, setShowFeedback }}>
          <Footer FeedbackContext={FeedbackContext} user={user} />
          <Feedback FeedbackContext={FeedbackContext} />
        </FeedbackContext.Provider>
      </Box>
    </Grommet>
  );
}

export default App;
