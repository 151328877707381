import { Box, Button } from 'grommet';
import React from 'react';
import { error, normalize, PATH, toDeserializedToken } from '../../Util';
import { useLocation } from 'wouter';
import Platforms from '../../Platforms.json';
import Facebook from '../../Facebook';
import { useAPI } from '../Firebase';

function AddFacebook({ tokens = [], setTokens }) {
  const [, setLocation] = useLocation();
  const API = useAPI();

  const fbTokens = tokens.filter(
    (token) => token.platform === Platforms.FACEBOOK
  );

  return (
    <Box pad="medium" background="light-2" width="500px">
      <Button
        primary
        label="Login with Facebook"
        onClick={async () => {
          try {
            const data = await Facebook.login();
            const { accessToken, userID } = data.authResponse || {};

            // confirm that this fb user isnt linked before proceeding
            if (fbTokens.filter((t) => t.pid === userID).length > 0) {
              setLocation(normalize(PATH.Home));
              return;
            }

            const { name, id } = await Facebook.getName(accessToken);
            const upgradeToken = await API.upgradeFbToken({
              token: accessToken,
            });
            const igToken = toDeserializedToken(
              Platforms.FACEBOOK,
              upgradeToken?.data?.upgrade,
              name,
              id
            );
            const temp = tokens.concat(igToken);
            setTokens(temp);
            await API.updateTokens(temp);
            setLocation(normalize(PATH.Home));
          } catch (e) {
            error(e);
          }
        }}
      />
    </Box>
  );
}

export default AddFacebook;
