import React, { useState } from 'react';
import {
  Box,
  Button,
  Anchor,
  CheckBox,
  RadioButtonGroup,
  Heading,
} from 'grommet';
import { PATH } from '../../Util';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import { Widget } from './editor/Widget';
import { v1 as uuid } from 'uuid';

const Grid = WidthProvider(GridLayout);

const STATES = { VIEW: 'View', EDIT: 'Edit' };

export function View({ campaigns = [], setCampaigns = (c) => {}, campaign }) {
  const [viewState, setViewState] = useState(STATES.VIEW);
  const [shareable, setShareable] = useState(campaign.public || false);
  const [layout, setLayout] = useState([]);

  const editMode = viewState === STATES.EDIT;
  return (
    <Box direction="column" flex fill pad="large" gap="small">
      <Box direction="row" alignContent="start">
        <Heading margin="none" level="2">
          <Anchor href={PATH.ListCampaign} label="Campaigns >" />{' '}
          {campaign.title}
        </Heading>
      </Box>
      <Box direction="row">
        <Box direction="row" alignContent="start" flex>
          <RadioButtonGroup
            name="doc"
            options={[STATES.VIEW, STATES.EDIT]}
            value={viewState}
            onChange={(e) => setViewState(e.target.value)}
            direction="row"
          />
        </Box>
        <Box direction="row" alignContent="end">
          <CheckBox
            checked={shareable}
            label="Shareable"
            onChange={(e) => setShareable(e.target.checked)}
          />
        </Box>
      </Box>
      {editMode && (
        <Box direction="row">
          <Box direction="row" alignContent="start" flex>
            <Button primary label="Save" />
          </Box>
          <Box direction="row" alignContent="end">
            <Button
              secondary
              label="+ New Widget"
              onClick={(e) =>
                setLayout((old) =>
                  old.concat({
                    i: uuid(),
                    x: 0,
                    y: Infinity,
                    w: 4,
                    h: 1,
                  })
                )
              }
            />
          </Box>
        </Box>
      )}
      <Box
        flex
        fill
        background={editMode ? 'light-1' : ''}
        margin={{ top: 'large' }}
      >
        <Grid
          className="layout"
          layout={layout}
          cols={12}
          rowHeight={100}
          onLayoutChange={(layout) => setLayout(layout)}
          isDraggable={editMode}
          isResizable={editMode}
        >
          {layout.map((widget) => (
            <div key={widget.i}>
              <Widget data={widget} editMode={editMode} />
            </div>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
