import React from 'react';
import { Box } from 'grommet';

export function Widget({ data, editMode }) {
  const style = editMode ? { background: 'light-4' } : {};
  return (
    <Box {...style} flex fill>
      key: {data.i}
    </Box>
  );
}
