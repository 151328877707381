import React, { useState } from 'react';
import { Box, Button, Anchor, CheckBox } from 'grommet';
import { normalize, PATH } from '../../Util';

export function List({ campaigns = [], setCampaigns = (c) => {} }) {
  const [checked, setChecked] = useState(false);
  const visible = checked ? campaigns : campaigns.filter((c) => c.active);

  const row = (data, index) => {
    return (
      <Box
        pad="small"
        direction="row"
        alignContent="center"
        background={index % 2 === 0 ? 'light-3' : 'light-5'}
        key={data.cid}
      >
        <Box flex direction="row">
          <Anchor
            href={normalize(`${PATH.Campaign}/${data.cid}`)}
            label={data.title}
          />
        </Box>
        <Button
          color="status-critical"
          onClick={(e) => {
            console.log(`TODO: Disable campaign ${data.cid} (${data.title})`);
            setCampaigns(
              campaigns.map((c) => {
                if (c.cid === data.cid) {
                  c.active = false;
                }

                return c;
              })
            );
          }}
        >
          Archive
        </Button>
      </Box>
    );
  };

  return (
    <Box direction="column" width="large">
      <Box direction="column" width="large" pad="xsmall">
        <CheckBox
          checked={checked}
          label="Show Archived"
          onChange={(event) => setChecked(event.target.checked)}
        />
      </Box>
      {visible.map(row)}
      <Box
        direction="column"
        width="large"
        pad="xsmall"
        alignContent="center"
        align="center"
      >
        <Box direction="row">
          <Button label="Load More..." />
        </Box>
      </Box>
    </Box>
  );
}
