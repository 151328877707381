import { set } from './Storage';
import { timestamp, error } from './Util';

window.getLoginStatus = getLoginStatus;

function handleAuthResponse(response = {}) {
  if (!response) return Promise.reject('Invalid data.');
  switch (response.status) {
    case 'connected':
      const {
        accessToken,
        data_access_expiration_time,
        expiresIn,
        graphDomain,
        signedRequest,
        userID,
      } = response.authResponse || {};
      set('token', accessToken);
      set('expiration', data_access_expiration_time);
      set('duration', expiresIn);
      set('login', timestamp());
      set('domain', graphDomain);
      set('signed', signedRequest);
      set('id', userID);
      return Promise.resolve(response.authResponse);
    case 'not_authorized':
    case 'unknown':
    default:
      return Promise.reject(response);
  }
}

// TODO: Generate access token: https://developers.facebook.com/docs/facebook-login/access-tokens#generating-an-app-access-token
function getLoginStatus() {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((res) =>
      handleAuthResponse(res).then(resolve).catch(reject)
    );
  });
}

/**
 * The primary list of facebook permissions used.
 *
 * @returns {String} The CSV string of perms
 */
function getPermissionsScope() {
  return [
    'email',
    'pages_show_list',
    'instagram_basic',
    'instagram_manage_comments',
    'instagram_manage_insights',
    'pages_read_engagement',
    'pages_manage_metadata',
    'public_profile',
  ].join(',');
}

function login() {
  return new Promise((resolve, reject) => {
    window.FB.login(
      function (response) {
        if (response.status === 'connected') {
          return resolve(response); // Logged into your webpage and Facebook.
        }

        // The person is not logged into your webpage or we are unable to tell.
        return reject(response);
      },
      { scope: getPermissionsScope() }
    );
  });
}

function responseHandler(response, resolve, reject) {
  if (!response) return reject(`An error has occured.`);
  if (response.error) return reject(response.error.message || response.error);
  return resolve(response);
}

function params(param) {
  const access_token = param.token || window.FB.getAccessToken();
  delete param.token;
  return {
    access_token,
    ...param,
  };
}

const getName = (token) =>
  new Promise((resolve, reject) =>
    window.FB.api('/me', params({ fields: 'name', token }), (res) =>
      responseHandler(res, resolve, reject)
    )
  );

const getAccounts = (token, config = {}) =>
  new Promise((resolve, reject) =>
    window.FB.api(
      '/me/accounts',
      params({ fields: 'name,id', limit: 10000, ...config, token }),
      (res) => responseHandler(res, resolve, reject)
    )
  );

const getIgAccounts = (token, fbPage) =>
  new Promise((resolve, reject) =>
    window.FB.api(
      `/${fbPage}`,
      params({
        fields: 'instagram_business_account{id,name,username}',
        limit: 10000,
        token,
      }),
      (res) => responseHandler(res, resolve, reject)
    )
  );

const getMoreIgFields = (token, url, fields, limit = 50, paging = {}) =>
  new Promise((resolve, reject) => {
    const data = typeof fields === 'string' ? { fields } : { ...fields };
    return window.FB.api(
      url,
      'get',
      params({
        ...data,
        limit,
        ...paging,
        token,
      }),
      (res) => responseHandler(res, resolve, reject)
    );
  });

const getIgFields = async (
  token,
  url,
  fields,
  limit = 50,
  all = false,
  prev = [],
  paging,
  filterFn
) => {
  try {
    let res = await getMoreIgFields(token, url, fields, limit, paging);
    const next = res?.paging?.next;
    const before = res?.paging?.cursors?.before;
    const after = res?.paging?.cursors?.after;
    const onlyCursorPagination = !next && !before && after;
    const rawData = res.data || res;
    const data = filterFn ? filterFn(rawData) : rawData;
    const outData = prev.concat(data);

    // we want all the data
    if (all || outData.length < limit) {
      // if there is more, get it
      if (next) {
        return await getIgFields(token, next, fields, limit, all, outData);
      }
      // this is all the data, no weird cursor pagination
      else if (!onlyCursorPagination) {
        return outData;
      }
    }

    // escape if we're not getting data anymore.
    if (rawData.length === 0) {
      return outData;
    }

    if (rawData.length < limit && onlyCursorPagination) {
      return await getIgFields(
        token,
        url,
        fields,
        limit - data.length,
        all,
        outData,
        {
          after,
        }
      );
    }

    return outData;
  } catch (e) {
    error(e);
    return [];
  }
};

const Facebook = {
  getLoginStatus,
  getPermissionsScope,
  login,
  getName,
  getAccounts,
  getIgAccounts,
  getIgFields,
};

export default Facebook;
