import React, { useState } from 'react';
import { Box, Button, Card, CardBody, FormField, TextInput } from 'grommet';
import { normalize, PATH } from '../../Util';
import { useLocation } from 'wouter';

export function Add({ campaigns = [], setCampaigns = (c) => {} }) {
  const [title, setTitle] = useState('');
  const [, setLocation] = useLocation();

  return (
    <Box direction="column" width="medium">
      <Card background="light-1">
        <CardBody pad="medium">
          <Box pad="medium" direction="column">
            <FormField label="Campaign Title">
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormField>

            <Button
              label="Create"
              onClick={() => {
                const cid = 0;
                setCampaigns((c) =>
                  c.concat({
                    title,
                    cid,
                    active: true,
                  })
                );
                setLocation(normalize(`${PATH.Campaign}/${cid}`));
              }}
            />
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
}
