import { Card, CardBody, Box, Text, Button } from 'grommet';
import React from 'react';
import { useFirebase } from '../component/Firebase';
import Facebook from '../Facebook';

export default function LoginPage() {
  const firebase = useFirebase();

  return (
    <Card background="light-1">
      <CardBody pad="large">
        <Box>
          <Text margin={{ bottom: '30px' }}>Welcome to measure.social</Text>
          <Button
            primary
            label="Login with Facebook"
            onClick={() => {
              // Start a sign in process for an unauthenticated user.
              var provider = new firebase.auth.FacebookAuthProvider();
              Facebook.getPermissionsScope()
                .split(',')
                .forEach((perm) => {
                  provider.addScope(perm);
                });
              firebase.auth().signInWithRedirect(provider);
            }}
          />
        </Box>
      </CardBody>
    </Card>
  );
}
