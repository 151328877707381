import React, { useState } from 'react';
import { Box, Button, CheckBox, Heading, Layer, TextArea } from 'grommet';
import { debug } from '../Util';
import { useAPI } from './Firebase';

function Feedback({ FeedbackContext }) {
  const API = useAPI();
  const [text, setText] = useState('');
  const [checked, setChecked] = useState(false);

  return (
    <FeedbackContext.Consumer>
      {({ showFeedback, setShowFeedback }) => {
        if (!showFeedback) return <React.Fragment></React.Fragment>;

        const close = () => {
          setText('');
          setChecked(false);
          setShowFeedback(false);
        };

        return (
          <Layer onEsc={close} onClickOutside={close}>
            <Box pad="medium" flex>
              <Heading level={2}>Feedback</Heading>
              <Box pad="small">
                <TextArea
                  id="feedback-input"
                  name="feedback"
                  value={text}
                  placeholder="Comments and Feedback"
                  onChange={(e) => setText(e.target.value)}
                ></TextArea>
              </Box>
              <Box pad="small" flex>
                <CheckBox
                  checked={checked}
                  label="I am interested in communications about this feedback"
                  onChange={(event) => setChecked(event.target.checked)}
                />
              </Box>
              <Box
                direction="row"
                pad="small"
                flex
                gap="medium"
                justify="center"
              >
                <Button label="Close" onClick={close} />
                <Button
                  label="Submit"
                  onClick={async () => {
                    setShowFeedback(false);
                    debug('form', { text, checked });
                    debug(await API.createFeedback({ text, checked }));
                  }}
                  primary
                />
              </Box>
            </Box>
          </Layer>
        );
      }}
    </FeedbackContext.Consumer>
  );
}

export default Feedback;
