import React, { useState } from 'react';
import { Box, Button, Text, TextInput } from 'grommet';
import { useAPI } from '../component/Firebase';
import { error } from '../Util';

function BetaRequest({ user, updateUser }) {
  const [beta, setBeta] = useState(user.beta);
  const [code, setCode] = useState('');
  const API = useAPI();

  function PendingRequest() {
    return (
      <Box gap="medium" pad="medium">
        <Text>
          Thanks for you interest in measure.social, your beta access is
          pending!
        </Text>
        <Box gap="small">
          <Text>Already have a code?</Text>
          <TextInput value={code} onChange={(e) => setCode(e.target.value)} />
          <Button
            onClick={async () => {
              try {
                const { data } = await API.redeemCode({ code });
                updateUser(data.beta);
              } catch (e) {
                error(e);
              }
            }}
            label="Submit"
          />
        </Box>
      </Box>
    );
  }

  function NewRequest() {
    return (
      <Box gap="medium">
        <Text>Thank you for your interest in measure.social!</Text>
        <Button
          onClick={async () => {
            try {
              const { data } = await API.requestAccess();
              setBeta(data.beta);
            } catch (e) {
              error(e);
            }
          }}
          label="Request beta access now!"
        />
      </Box>
    );
  }

  if (beta === 'request') return PendingRequest();
  return NewRequest();
}

export default BetaRequest;
