import InstagramUtil from './InstagramUtil';
import { get } from './Storage';

export const timestamp = () => Math.floor(Date.now() / 1000);

// Logout time in seconds (30 mins)
export const LOGOUT_TIME = 1800;

/* Basic log support
0 - off
1 - critical
2 - error
3 - log
4 - debug
*/
export const DEBUG = parseInt(process.env.REACT_APP_DEBUG) || 1;
export const critical = (...content) =>
  DEBUG && DEBUG >= 1 && console.error(`[CRITICAL]`, ...content);
export const error = (...content) =>
  DEBUG && DEBUG >= 2 && console.error(`[ERROR]`, ...content);
export const log = (...content) =>
  DEBUG && DEBUG >= 3 && console.log(`[LOG]`, ...content);
export const debug = (...content) =>
  DEBUG && DEBUG >= 4 && console.log(`[DEBUG]`, ...content);

export const PATH = {
  Login: '/',
  Home: '/home/',
  Settings: '/settings/',
  Add: '/add/',
  Instagram: '/instagram/',
  Campaign: '/campaign/',
  AddCampaign: '/campaign/add',
  ListCampaign: '/campaign/list',
  Admin: '/admin',
};

export function normalize(path) {
  return path.toString().toLowerCase().replace(/\/+/gi, '/');
}

export const IG = InstagramUtil;

export function getUserDoc() {
  try {
    return JSON.parse(get('userDoc'));
  } catch (e) {
    return false;
  }
}

export function toSerializedToken(
  platform,
  value,
  display,
  pid,
  active = true
) {
  return {
    [`${platform}-${pid}`]: {
      value,
      active,
      display,
      platform,
      pid,
    },
  };
}

export function toDeserializedToken(
  platform,
  value,
  display,
  pid,
  active = true,
  report = true
) {
  return {
    platform,
    pid,
    value,
    active,
    display,
    report,
  };
}

export function isProd() {
  const hostname = window.location.hostname;
  const local = /^localhost$/i.test(hostname);
  const branch = /\.pages\.dev$/i.test(hostname);
  const primary = /^measure\.social$/i.test(hostname);
  const isTrue = (item) => item === true;
  const dev = [local, branch].some(isTrue);
  const prod = [primary].some(isTrue);
  if (prod && !dev) {
    return true;
  }
  return false;
}

export function toBool(val) {
  switch (`${val}`.toString().toLowerCase()) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    case 'undefined':
    case 'null':
    default:
      return false;
  }
}
