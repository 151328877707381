import { Card, CardBody, CardHeader, Text, Heading, Tip } from 'grommet';
import React from 'react';

function calcData(data, valueKey, fn) {
  return data.reduce(
    (acc, cur) => {
      if (fn) {
        cur = fn(cur);
      }
      let _high = acc._high;
      if (_high === undefined || cur[valueKey] > _high) {
        _high = cur[valueKey];
      }

      return { [valueKey]: (acc[valueKey] || 0) + (cur[valueKey] || 0), _high };
    },
    { [valueKey]: 0 }
  );
}

function Metric({
  label = '', // display label for the metric
  tooltip = '', // the html hover tooltip to display
  data = [], // array of objs with data in them
  valueKey = '', // value key in data objs
  avg = false, // bool, whether or not to include the avg
  avgUnit = undefined, // the label unit to display with the avg metric
  fn = undefined, // custom function to run the data through
  hideVal = false, // hide the primary value
  recent = false, // bool, whether or not to show recent
  recentCount = 30, // number of recents
  high = false, // bool, whether or not to show high
}) {
  const value = calcData(data, valueKey, fn);
  const rData = calcData(data.slice(0, recentCount), valueKey, fn);

  return (
    <Card background="light-1" pad="medium" width="medium">
      <CardHeader>
        <Tip content={tooltip}>
          <Heading level="3">{label}</Heading>
        </Tip>
      </CardHeader>
      <CardBody>
        {!hideVal && (
          <Text size="xlarge">{value[valueKey].toLocaleString()}</Text>
        )}
        {avg && (
          <Tip content={`The average number of ${label} over all Posts`}>
            <Text size={hideVal ? 'xlarge' : 'medium'}>
              Avg {(value[valueKey] / data.length).toFixed(2)}
              {avgUnit || ''}
            </Text>
          </Tip>
        )}
        {recent && (
          <Tip
            content={`The average number of ${label} over the last ${recentCount} Posts`}
          >
            <Text size={hideVal ? 'xlarge' : 'medium'}>
              L{recentCount} {(rData[valueKey] / recentCount).toFixed(2)}
              {avgUnit || ''}
            </Text>
          </Tip>
        )}
        {high && (
          <Tip
            content={`The all time high, largest count, of ${label} in any Post`}
          >
            <Text size={hideVal ? 'xlarge' : 'medium'}>
              ATH {(value._high || 0).toFixed(2)}
              {avgUnit || ''}
            </Text>
          </Tip>
        )}
      </CardBody>
    </Card>
  );
}

export default Metric;
