import {
  Card,
  CardBody,
  CardFooter,
  Box,
  Button,
  CardHeader,
  Stack,
  Layer,
  Heading,
  Paragraph,
  Anchor,
} from 'grommet';
import {
  Add as AddIcon,
  Help as HelpIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Close as CloseIcon,
} from 'grommet-icons';
import { useState } from 'react';
import Spinner from '../component/Spinner';
import { PATH, normalize, error, debug } from '../Util';
import React from 'react';
import Platforms from '../Platforms.json';
import { useAPI, useFeatureFlags } from '../component/Firebase';

const appPages = [Platforms.INSTAGRAM, Platforms.WIZARD].map((i) =>
  i.toLowerCase()
);

export default function Home({
  loading,
  tokens = [],
  setTokens,
  campaigns = [],
}) {
  const API = useAPI();
  const flags = useFeatureFlags();
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayData, setOverlayData] = useState({
    pid: 0,
    platform: '',
    display: '',
  });

  debug('tokens', tokens);
  if (loading) return <Spinner />;

  const deleteCard = async () => {
    const { pid, platform } = overlayData;

    try {
      const _tokens = [...tokens].filter(
        (t) => !(t.pid === pid && t.platform === platform)
      );
      setTokens && setTokens(() => _tokens);
      API.updateTokens(_tokens);
    } catch (e) {
      error(e);
    } finally {
      setShowOverlay(false);
    }
  };

  const DeleteConfirmation = () => {
    return (
      <Card background="light-1">
        <CardHeader pad="medium" flex justify="center">
          <Heading level="3" margin="small">
            Remove measure.social account access
          </Heading>
        </CardHeader>
        <CardBody pad={{ horizontal: 'large', vertical: 'small' }}>
          <Paragraph>
            To remove measure.social access to the{' '}
            {overlayData?.platform.toString().toLowerCase()} account "
            {overlayData?.display}", please click Delete.
          </Paragraph>
          <Paragraph>
            This action is not reversible. Once the app is unlinked, we will no
            longer be able to access data on your behalf.
          </Paragraph>
        </CardBody>
        <CardFooter pad="medium" background="light-2" justify="center">
          <Box height="40px" flex justify="center" direction="row" gap="large">
            <Button
              label="Cancel"
              alignSelf="center"
              onClick={() => setShowOverlay(false)}
            />
            <Button
              label="Delete"
              alignSelf="center"
              color="status-critical"
              onClick={deleteCard}
            />
          </Box>
        </CardFooter>
      </Card>
    );
  };

  const AppIcon = (platform) => {
    const size = 'xlarge';
    switch (platform.toString().toLowerCase()) {
      case Platforms.FACEBOOK:
        return <FacebookIcon size={size} />;
      case Platforms.INSTAGRAM:
        return <InstagramIcon size={size} />;
      case Platforms.WIZARD:
        return <AddIcon size={size} />;
      default:
        return <HelpIcon size={size} />;
    }
  };

  const AppCard = (
    { platform, pid = 0, display = 'New', value = undefined, active = false },
    label = 'View',
    loc
  ) => {
    return (
      <Stack anchor="top-right" key={`${platform}-${pid}`}>
        <Box pad="small">
          <Card background="light-1">
            <CardHeader pad="medium" flex justify="center">
              {display}
            </CardHeader>
            <CardBody pad={{ horizontal: 'large', vertical: 'small' }}>
              <Box
                pad={{ horizontal: 'large', vertical: 'small' }}
                direction="column"
                align="center"
              >
                {AppIcon(platform)}
              </Box>
            </CardBody>
            <CardFooter pad="medium" background="light-2" justify="center">
              <Box height="40px" flex justify="center">
                {appPages.includes(platform.toLowerCase()) && (
                  <Button
                    label={label}
                    href={loc || normalize(`/${platform}/${pid}`)}
                    alignSelf="center"
                  />
                )}
              </Box>
            </CardFooter>
          </Card>
        </Box>
        {display !== 'New' && (
          <Box
            pad="small"
            background="brand"
            round
            onClick={() => {
              setOverlayData({ platform, pid, display });
              setShowOverlay(true);
            }}
          >
            <CloseIcon size="small" />
          </Box>
        )}
      </Stack>
    );
  };

  const CampaignCard = (campaign) => {
    const { title, cid } = campaign;
    return (
      <Box pad="small" flex key={cid}>
        <Card background="light-1">
          <CardHeader pad="medium" flex justify="center">
            {title}
          </CardHeader>
          <CardFooter pad="small" background="light-2" justify="center">
            <Box height="40px" flex justify="center">
              <Button
                label="View"
                href={normalize(`/campaign/${cid}`)}
                alignSelf="center"
              />
            </Box>
          </CardFooter>
        </Card>
      </Box>
    );
  };

  const CampaignLinks = () => {
    return (
      <Box
        direction="row"
        pad={{ top: 'small', horizontal: 'small', bottom: 'large' }}
        flex
      >
        <Box direction="row" flex>
          <Anchor href={normalize(PATH.AddCampaign)} label="New Campaign" />
        </Box>
        <Box direction="row" alignSelf="end">
          <Anchor href={normalize(PATH.ListCampaign)} label="All Campaigns" />
        </Box>
      </Box>
    );
  };

  const AppWizard = () =>
    AppCard({ platform: Platforms.WIZARD }, 'Link App', PATH.Add);
  const AppList = () => tokens.map((app) => AppCard(app));

  return (
    <Box direction="column">
      {flags.enable_campaigns && (
        <>
          <Box direction="row" gap="large">
            {[...campaigns.map(CampaignCard)].slice(0, 3)}
          </Box>
          {CampaignLinks()}
        </>
      )}

      <Box direction="row" gap="large">
        {[...AppList(), AppWizard()]}
      </Box>
      {showOverlay && (
        <Layer
          onEsc={() => setShowOverlay(false)}
          onClickOutside={() => setShowOverlay(false)}
        >
          {DeleteConfirmation()}
        </Layer>
      )}
    </Box>
  );
}
