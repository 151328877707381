import { debug } from './Util';

const INSTA_REGEX = /^(https?:\/\/)(www.)?instagram.com\/p\/(.*)/i;

const getInstaShortCode = (url) => {
  const [, , , rem] = INSTA_REGEX.exec(url);
  let out;

  if (rem.includes('/')) {
    [out] = rem.split('/');
  } else if (rem) {
    out = rem;
  } else {
    out = url;
  }
  return out;
};

const getTimestampDate = (timestamp = '') => {
  if (!timestamp) return '';
  let [date] = timestamp.toString().split('T');
  return date ?? '';
};
const getPostObjLabel = (i, labeled = true) => {
  if (!i || Object.keys(i).length === 0) return '';

  debug('getPostObjLabel', i);
  const { username = '', timestamp = 0, caption = '' } = i;
  const usernameLabel = labeled ? `${username} - ` : '';

  return `${getTimestampDate(
    timestamp
  )} (${usernameLabel}${caption.toString().slice(0, 60)}...)`;
};

const InstagramUtil = {
  INSTA_REGEX,
  getInstaShortCode,
  getTimestampDate,
  getPostObjLabel,
};

export default InstagramUtil;
