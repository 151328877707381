import dayjs from 'dayjs';
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Box,
  CheckBox,
  TextInput,
  Button,
  List,
  Tip,
} from 'grommet';
import React from 'react';
import { useState } from 'react';
import { error, debug } from '../../Util';

function RandomCommentPicker({ label = 'Pick Winner', comments = [] }) {
  const [useTag, setUseTag] = useState(false);
  const [useWord, setWordTag] = useState(false);
  const [normal, setNormal] = useState(true);
  const [wordSensitive, setWordSensitive] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [winner, setWinner] = useState();

  // normalize comment data
  const getCommentPool = () => {
    debug('getCommentPool %O', comments);
    let haystack = comments.filter((c) => {
      if (useTag && !c.text.includes('@')) {
        return false;
      }
      if (useWord) {
        if (wordSensitive && !c.text.includes(searchWord)) {
          return false;
        } else if (!new RegExp(searchWord, 'i').test(c.text)) {
          return false;
        }
      }

      return true;
    });
    debug('getCommentPool haystack %O', haystack);

    if (normal) {
      let map = {};

      haystack.forEach((c) => {
        map[c.username] = map[c.username] || [];
        map[c.username].push(c);
      });

      return Object.keys(map).map((username) => ({
        username,
        comments: map[username],
      }));
    } else {
      return haystack.map((c) => ({
        username: c.username,
        comments: [c],
      }));
    }
  };

  return (
    <Card background="light-1" pad="medium" width="medium">
      <CardHeader>
        <Heading level="3">{label}</Heading>
      </CardHeader>
      <CardBody>
        <Box direction="column" gap="xsmall" pad="xsmall">
          <CheckBox
            label="Must tag another @person"
            checked={useTag}
            onChange={(e) => setUseTag(e.target.checked)}
          />
          <CheckBox
            label="Comment includes word"
            checked={useWord}
            onChange={(e) => setWordTag(e.target.checked)}
          />
          <CheckBox
            label="Normalize Submissions"
            checked={normal}
            onChange={(e) => setNormal(e.target.checked)}
          />
          {useWord && (
            <Box direction="row" gap="xsmall">
              <CheckBox
                label="Case Sensitive"
                checked={wordSensitive}
                onChange={(e) => setWordSensitive(e.target.checked)}
              />
              <TextInput
                placeholder="Search word(s)"
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              />
            </Box>
          )}
          <Box direction="row" gap="xsmall">
            {winner && (
              <Button
                label="Hide"
                onClick={() => {
                  setWinner(false);
                }}
              />
            )}
            <Button
              label="Pick Random Comment"
              onClick={() => {
                try {
                  const filtered = getCommentPool();
                  const win =
                    filtered[Math.floor(Math.random() * filtered.length)];
                  debug('filtered %O', filtered);
                  debug('win %O', win);
                  setWinner({
                    ...win,
                    entries: comments.length,
                    validEntries: filtered.length,
                  });
                } catch (e) {
                  error(e);
                }
              }}
            />
          </Box>
          {winner && (
            <Box gap="xsmall">
              <Text>Total Entries: {winner?.entries}</Text>
              <Text>Unique Entries: {winner?.validEntries}</Text>
              <Text>Winner: @{winner?.username}</Text>
              <List primaryKey="text" data={winner?.comments}>
                {(item) => (
                  <Tip
                    content={dayjs(winner?.timestamp).toString()}
                    dropProps={{ align: { left: 'right' } }}
                  >
                    {item.text}
                  </Tip>
                )}
              </List>
            </Box>
          )}
        </Box>
      </CardBody>
    </Card>
  );
}

export default RandomCommentPicker;
