import { Card, CardBody, CardHeader, Text, Heading, Tip } from 'grommet';
import React from 'react';

function StaticMetric({
  label = '', // display label for the metric
  data = [],
  tooltip = '',
}) {
  const tooltipWrap = (el) =>
    tooltip ? <Tip content={tooltip}>{el}</Tip> : el;

  return (
    <Card background="light-1" pad="medium" width="medium">
      <CardHeader>
        <Heading level="3">{tooltipWrap(label)}</Heading>
      </CardHeader>
      <CardBody>
        {data.map((item, index, all) => {
          const { display, value, unit } = item;
          return (
            <Text
              size={all.length <= 1 ? 'xlarge' : 'large'}
              key={`${label}-${index}`}
              style={{ textTransform: 'capitalize' }}
            >
              {display === undefined ? '' : `${display} `}
              {value === undefined || isNaN(value)
                ? ''
                : value.toLocaleString()}
              {unit === undefined ? '' : unit}
              {display === undefined && value === undefined && !isNaN(item)
                ? item
                : ''}
            </Text>
          );
        })}
      </CardBody>
    </Card>
  );
}

export default StaticMetric;
