import { Box, Text, Meter, Stack } from 'grommet';
import React from 'react';
import { useState } from 'react';

function Orb({ day, month, total, metric }) {
  const [active, setActive] = useState(0);
  const [label, setLabel] = useState('');

  return (
    <Box align="center" justify="center" flex>
      <Stack anchor="center">
        <Meter
          type="circle"
          values={[
            {
              value: day,
              onHover: (over) => {
                setActive(over ? day : 0);
                setLabel(over ? 'today' : undefined);
              },
            },
            {
              value: month,
              onHover: (over) => {
                setActive(over ? month : 0);
                setLabel(over ? 'this month' : undefined);
              },
            },
            {
              value: total,
              onHover: (over) => {
                setActive(over ? total : 0);
                setLabel(over ? 'total' : undefined);
              },
            },
          ]}
          max={total}
          size="small"
          thickness="medium"
        />
        <Box align="center" flex>
          <Box direction="row" align="center" pad={{ bottom: 'xsmall' }} flex>
            <Text size="xxlarge" weight="bold">
              {active || total}
            </Text>
          </Box>
          <Text>{label || metric}</Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default Orb;
