import { Box, Button, Footer as GFooter, Text } from 'grommet';
import React from 'react';
import { Chat } from 'grommet-icons';

function Footer({ FeedbackContext, user }) {
  return (
    <GFooter background="brand" pad="medium">
      <Box>
        <Text>Copyright &copy; measure.social {new Date().getFullYear()}</Text>
        <Text>Made with &hearts; in Texas</Text>
      </Box>
      {user && (
        <FeedbackContext.Consumer>
          {({ showFeedback, setShowFeedback }) => (
            <Button
              onClick={() => {
                setShowFeedback(true);
              }}
            >
              <Chat size="large" />
            </Button>
          )}
        </FeedbackContext.Consumer>
      )}
    </GFooter>
  );
}

export default Footer;
