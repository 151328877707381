import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/remote-config';
import { isProd, log, debug } from '../Util';
import API from '../API';

const config = isProd()
  ? {
      apiKey: 'AIzaSyCH8QsvG_9Dy0a9kD8Bq9QQznqELTwRxoU',
      authDomain: 'measure-social-prod.firebaseapp.com',
      projectId: 'measure-social-prod',
      storageBucket: 'measure-social-prod.appspot.com',
      messagingSenderId: '569463302393',
      appId: '1:569463302393:web:c97e2ec38edb78d7dcda28',
      measurementId: 'G-H8K7YWCVNX',
    }
  : {
      apiKey: 'AIzaSyCvEI0cloy6_jz7UdYUJx6SLCGXpt8ZrxI',
      authDomain: 'measure-social-dev.firebaseapp.com',
      projectId: 'measure-social-dev',
      storageBucket: 'measure-social-dev.appspot.com',
      messagingSenderId: '465163724594',
      appId: '1:465163724594:web:144a62b25c687e1d7a192b',
      measurementId: 'G-V88YD1VZTH',
    };

firebase.initializeApp(config);
firebase.analytics();

if (process.env.REACT_APP_DEV_AUTH.toString().toLowerCase() === 'true') {
  firebase.auth().useEmulator('http://localhost:9099/');
}
if (process.env.REACT_APP_DEV.toString().toLowerCase() === 'true') {
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.functions().useEmulator('localhost', 5001);
}

const rcDefault = {
  enable_campaigns: false,
  announcement: '',
};
const remoteConfig = firebase.remoteConfig();
if (!isProd()) {
  remoteConfig.settings.minimumFetchIntervalMillis = 60000;
}
remoteConfig.defaultConfig = rcDefault;

const FirebaseContext = React.createContext({});
const FeatureFlagsContext = React.createContext(rcDefault);
const APIContext = React.createContext({});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export function useAPI() {
  return useContext(APIContext);
}

export function FirebaseProvider({ children }) {
  const [fire] = useState(firebase);
  const [flags, setFlags] = useState({});
  const [api, setApi] = useState(API(fire, flags));

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        if (!activated) log('remoteConfig not activated');
        return {
          enable_campaigns: remoteConfig.getBoolean('enable_campaigns'),
          announcement: remoteConfig.getString('announcement'),
        };
      })
      .then((newConfig) => {
        debug('flags', newConfig);
        setFlags((prev) => ({
          ...prev,
          ...newConfig,
        }));
      });
  }, [fire]);

  useEffect(() => setApi(API(fire, flags)), [fire, flags]);

  return (
    <FirebaseContext.Provider value={fire}>
      <FeatureFlagsContext.Provider value={flags}>
        <APIContext.Provider value={api}>{children}</APIContext.Provider>
      </FeatureFlagsContext.Provider>
    </FirebaseContext.Provider>
  );
}
