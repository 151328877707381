import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Box, TextInput, Button, Select } from 'grommet';
import { debug, error, IG, log } from '../../Util';
import StaticMetric from '../StaticMetric';
import RandomCommentPicker from './RandomCommentPicker';
import Facebook from '../../Facebook';
import Spinner from '../Spinner';

const { getPostObjLabel } = IG;
const FETCH_METHOD = {
  Select: 'Select',
  URL: 'URL',
};

function PostComments({ token, mediaData, totalFollowers }) {
  const [loading, setLoading] = useState(true);
  const [fetchMethod, setFetchMethod] = useState(FETCH_METHOD.Select);
  const [recentPosts, setRecentPosts] = useState([]);
  const [post, setPost] = useState();
  const [searchUrl, setSearchUrl] = useState('');
  const [postCommentData, setPostCommentData] = useState([]);
  const [impressionData, setImpressionData] = useState();
  const pid = token?.pid;
  const accessToken = token?.value;

  // on initial page load, load the most recent 100 captions for quick select
  useEffect(() => {
    try {
      setLoading(() => true);
      Facebook.getIgFields(
        accessToken,
        `/${pid}/media`,
        'caption,timestamp,id',
        100,
        false
      ).then((res) => {
        log('setRecentPosts', res);
        setRecentPosts(res);
      });
    } catch (e) {
      error(e);
    } finally {
      setLoading(() => false);
    }
  }, [pid, accessToken]);

  // Load the comments for a post when selected.
  useEffect(() => {
    if (!post?.id) return;
    const POST_COMMENT_FIELDS = 'text,username,timestamp,id';

    function getPostImpressions() {
      let fields;
      switch (post?.media_type) {
        case 'CAROUSEL_ALBUM':
          fields = {
            metric:
              'carousel_album_engagement,carousel_album_impressions,carousel_album_reach,carousel_album_saved,carousel_album_video_views',
          };
          break;
        case 'IMAGE':
          fields = { metric: 'engagement,impressions,reach,saved' };
          break;
        case 'VIDEO':
          fields = {
            metric: 'engagement,impressions,reach,saved,video_views',
          };
          break;
        default:
          return;
      }

      return Facebook.getIgFields(
        accessToken,
        `/${post?.id}/insights`,
        fields
      ).then((res) => {
        let transformed = res.reduce(
          (
            acc,
            { description: tooltip, name, period, title: label, values }
          ) => {
            const [data] = values;

            return {
              ...acc,
              [name]: {
                period,
                label,
                tooltip,
                data: [
                  {
                    ...data,
                    display: period,
                  },
                ],
              },
            };
          },
          {}
        );

        setImpressionData(transformed);
      });
    }

    try {
      setLoading(() => true);
      Facebook.getIgFields(
        accessToken,
        `/${post?.id}/comments`,
        POST_COMMENT_FIELDS,
        100,
        true
      ).then((res) => {
        log('getComments data', res);
        setPostCommentData(res);
        return getPostImpressions();
      });
    } catch (e) {
      error(e);
    } finally {
      setLoading(() => false);
    }
  }, [post, accessToken, setPostCommentData]);

  function getPostSelectionDOM(method) {
    switch (method) {
      case FETCH_METHOD.Select:
        return (
          <Box fill>
            <Select
              size="medium"
              options={recentPosts}
              placeholder={'Select a recent Post'}
              labelKey={(i) => getPostObjLabel(i, false)}
              valueKey={(i) => getPostObjLabel(i, false)}
              onChange={({ option }) => {
                try {
                  const { id } = option;
                  let data = mediaData.find((p) => p.id === id);
                  const final = Object.assign({}, data, option);
                  log('onChange', option, data, final);
                  setPost(final);
                } catch (e) {
                  error(e);
                }
              }}
            />
          </Box>
        );
      case FETCH_METHOD.URL:
        return (
          <Box direction="row" gap="small" fill>
            <TextInput
              placeholder="Post URL"
              value={searchUrl}
              onChange={(e) => {
                setSearchUrl(() => e.target.value);
              }}
            />
            <Button
              label="Search"
              onClick={async () => {
                if (!searchUrl) return;
                const needle = IG.getInstaShortCode(searchUrl);
                debug('search onClick getInstaShortCode', needle);
                debug('search onClick mediaData', mediaData);

                let final = mediaData.find((m) =>
                  ['shortcode', 'id', 'ig_id'].some(
                    (prop) => m && prop && needle && m[prop] === needle
                  )
                );
                log('search post', { ...post, ...final });
                if (!final) {
                  return error(
                    `Can't find postUrl for ${searchUrl} (${needle})`
                  );
                }

                setPost(() => ({ ...post, ...final }));
              }}
            />
          </Box>
        );
      default:
        return <></>;
    }
  }

  function getPostImpressionDOM() {
    if (!impressionData) return;

    let fields = Object.keys(impressionData);
    let count = Math.ceil(fields.length / 3);
    let data = [];
    for (let i = 0; i < count; i++) {
      let keys = fields.splice(0, 3);
      data.push(keys.map((k) => impressionData[k]));
    }
    log('getPostImpressionDOM data', data);

    return (
      <React.Fragment>
        {data.map((row, i) => (
          <Box direction="row" pad="small" gap="small" fill key={i}>
            {row.map((item, j) => (
              <StaticMetric {...item} key={`${i}-${j}`} />
            ))}
          </Box>
        ))}
      </React.Fragment>
    );
  }

  return loading ? (
    <Spinner />
  ) : (
    <Box direction="column" pad="small" gap="small" flex>
      <Box direction="row" pad="small" gap="small" fill>
        <Select
          size="medium"
          options={[FETCH_METHOD.Select, FETCH_METHOD.URL]}
          value={fetchMethod}
          onChange={({ option }) => {
            debug('fetchMethod onChange', option);
            setFetchMethod(option);
          }}
        />

        {getPostSelectionDOM(fetchMethod)}
      </Box>
      <Box direction="row" pad="small" gap="small" fill>
        <StaticMetric label="Comments" data={[post?.comments_count ?? 0]} />
        <StaticMetric label="Likes" data={[post?.like_count ?? 0]} />
        <StaticMetric
          label="Engagement"
          data={[
            {
              value: (
                ((post?.comments_count ?? 0 + post?.like_count ?? 0) /
                  totalFollowers) *
                100
              ).toFixed(2),
              unit: '%',
            },
          ]}
        />
      </Box>
      <Box direction="row" pad="small" gap="small" fill>
        <StaticMetric
          label="Day 1 Comments"
          tooltip="Number of comments made within the first 24 hours of posting. Does not include author comments."
          data={[
            postCommentData.filter((p) =>
              dayjs(p.timestamp).isBefore(
                dayjs(post?.timestamp ?? 0).add(1, 'day')
              )
            ).length,
          ]}
        />
        <StaticMetric
          label="Week 1 Comments"
          tooltip="Number of comments made within the first 7 days of posting. Does not include author comments."
          data={[
            postCommentData.filter((p) =>
              dayjs(p.timestamp).isBefore(
                dayjs(post?.timestamp ?? 0).add(1, 'week')
              )
            ).length,
          ]}
        />
        <RandomCommentPicker
          comments={postCommentData}
          label="Random Giveaway Winner"
        />
      </Box>
      {getPostImpressionDOM()}
    </Box>
  );
}

export default PostComments;
