import { Box, Text } from 'grommet';
import React from 'react';
import Metric from '../Metric';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { ResizeObserver } from '@juggle/resize-observer';
import useDimensions from 'react-cool-dimensions';
import dayjs from 'dayjs';

const getChartData = (raw = []) => {
  let ticks = {};

  raw.forEach((metric) => {
    const { title = '', description = '', values = [] } = metric;
    values.forEach((time) => {
      const { value, end_time } = time;

      ticks[end_time] = ticks[end_time] || {};
      ticks[end_time] = {
        ...ticks[end_time],
        time: dayjs(end_time).format('MM/DD'),
        title,
        [title]: value,
        description: {
          ...(ticks[end_time].description || {}),
          [title]: description,
        },
      };
    });
  });

  return Object.values(ticks);
};

const hourLabel = (input) => {
  switch (parseInt(input)) {
    case 0:
      return '12am';
    case 1:
      return '1am';
    case 2:
      return '2am';
    case 3:
      return '3am';
    case 4:
      return '4am';
    case 5:
      return '5am';
    case 6:
      return '6am';
    case 7:
      return '7am';
    case 8:
      return '8am';
    case 9:
      return '9am';
    case 10:
      return '10am';
    case 11:
      return '11am';
    case 12:
      return '12pm';
    case 13:
      return '1pm';
    case 14:
      return '2pm';
    case 15:
      return '3pm';
    case 16:
      return '4pm';
    case 17:
      return '5pm';
    case 18:
      return '6pm';
    case 19:
      return '7pm';
    case 20:
      return '8pm';
    case 21:
      return '9pm';
    case 22:
      return '10pm';
    case 23:
      return '11pm';
    default:
      return '';
  }
};

const activityChartData = (raw = []) => {
  let lines = [];
  let ticks = {};

  raw.forEach((metric) => {
    const { values = [] } = metric;
    values.forEach((time) => {
      const { value = {}, end_time } = time;
      const endTime = dayjs(end_time).format('MM/DD');

      Object.keys(value).forEach((dayTick) => {
        ticks[dayTick] = ticks[dayTick] || {
          hour: dayTick,
          label: hourLabel(dayTick),
        };
        ticks[dayTick] = {
          ...ticks[dayTick],
          [endTime]: value[dayTick],
        };
      });
      lines.push(endTime);
    });
  });

  return [lines, Object.values(ticks)];
};

function Overview({
  mediaData,
  totalFollowers,
  insightOverview = [],
  activeOverview = [],
}) {
  const { observe, width } = useDimensions({
    polyfill: ResizeObserver,
  });

  const dailyInsights = () => {
    return (
      <LineChart
        width={width}
        height={300}
        data={getChartData(insightOverview)}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line type="monotone" dataKey="Impressions" stroke="#3D138D" />
        <Line type="monotone" dataKey="Reach" stroke="#00739D" />
        <Line type="monotone" dataKey="Profile Views" stroke="#00C781" />
        <Line type="monotone" dataKey="Follower Count" />
        <Line type="monotone" dataKey="Website Clicks" stroke="#CCCCCC" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    );
  };

  const activityInsights = () => {
    const [lineLabels, data] = activityChartData(activeOverview);
    const FB_OFFSET = -7;
    const currentOffset = -1 * (new Date().getTimezoneOffset() / 60);
    const diff = currentOffset - FB_OFFSET;
    let startTime = dayjs();
    if (diff > 0) {
      startTime = startTime.subtract(diff, 'hours');
    } else {
      startTime = startTime.add(diff, 'hours');
    }

    const currentHourMin = startTime.hour();
    const currentHourMax = startTime.add(1, 'hour').hour();
    return (
      <LineChart
        width={width}
        height={300}
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        {lineLabels.map((lineLabel) => (
          <Line type="monotone" dataKey={`${lineLabel}`} key={lineLabel} />
        ))}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <ReferenceArea
          x1={hourLabel(currentHourMin)}
          x2={hourLabel(currentHourMax)}
          stroke="red"
          fill="red"
          fillOpacity={0.1}
          strokeOpacity={0.1}
          label="Current localized time"
        />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    );
  };

  return (
    <Box direction="column" pad="small" gap="small" flex>
      <Box direction="row" pad="small" gap="small" flex ref={observe}>
        {dailyInsights()}
      </Box>
      <Box
        direction="column"
        pad="small"
        gap="small"
        flex
        margin={{ top: '10px' }}
      >
        <Box direction="row" justify="center">
          <Text>Total Online Followers</Text>
        </Box>
        <Box direction="row">{activityInsights()}</Box>
      </Box>
      <Box direction="row" pad="small" gap="small" flex>
        <Metric
          label="Comments"
          tooltip="Total number of comments and replies on the post"
          data={mediaData}
          valueKey="comments_count"
          avg
          recent
          high
        />
        <Metric
          label="Likes"
          tooltip="Total number of likes on the post"
          data={mediaData}
          valueKey="like_count"
          avg
          recent
          high
        />
        <Metric
          label="Engagement"
          tooltip="Overall engagement rate of the post"
          data={mediaData}
          valueKey="engagement"
          avg
          fn={(data) => {
            const { comments_count, like_count } = data;
            return {
              ...data,
              ...{
                engagement:
                  ((like_count + comments_count) / totalFollowers) * 100,
              },
            };
          }}
          avgUnit="%"
          hideVal
          recent
          high
        />
      </Box>
    </Box>
  );
}

export default Overview;
