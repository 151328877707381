import { useCallback, useEffect, useState } from 'react';
import { Box, FormField, Select, TextInput, Button, Text } from 'grommet';
import React from 'react';
import { debug, error, normalize, PATH, toDeserializedToken } from '../../Util';
import { useLocation } from 'wouter';
import Platforms from '../../Platforms.json';
import Facebook from '../../Facebook';
import { useAPI } from '../Firebase';

function AddInstagram({ tokens, setTokens }) {
  const [fbOptions, setFbOptions] = useState({ display: '', value: undefined });
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(undefined);
  const [igAccount, setIgAccount] = useState(undefined);
  const [, setLocation] = useLocation();
  const API = useAPI();

  const fbAccounts = useCallback(() => {
    if (!tokens || tokens.length === 0) return [];
    return tokens.filter((token) => token.platform === Platforms.FACEBOOK);
  }, [tokens]);

  const igAccounts = useCallback(() => {
    if (!tokens || tokens.length === 0) return [];
    return tokens.filter((token) => token.platform === Platforms.INSTAGRAM);
  }, [tokens]);

  // On page load, pull the fb account list.
  useEffect(() => {
    if (!tokens || tokens.length === 0) return;
    const fb = fbAccounts();

    // If we only have 1 account, prepopulate the next form field
    if (fb.length === 1) {
      setFbOptions(fb[0]);
    }
  }, [tokens, setFbOptions, fbAccounts]);

  // On account selection, pull the fb accounts associated with that token
  useEffect(() => {
    if (!fbOptions || !fbOptions?.value) return;
    Facebook.getAccounts(fbOptions?.value).then((res) => {
      debug('fb accounts', res.data);
      setPageOptions(res.data);

      if (res.data.length === 1) {
        setSelectedPage(res.data[0]);
      }
    });
  }, [fbOptions, setPageOptions, setSelectedPage]);

  // On fb business page selection, pull the ig accounts
  useEffect(() => {
    if (!fbOptions || !fbOptions?.value) return;
    if (!selectedPage || !selectedPage?.id) return;
    Facebook.getIgAccounts(fbOptions?.value, selectedPage.id)
      .then((res) => {
        console.log('Facebook.getIgAccounts', res);
        setIgAccount(res?.instagram_business_account);
      })
      .catch(error);
  }, [fbOptions, selectedPage, setIgAccount]);

  const alreadyLinked = (username, pid) => {
    if (username === undefined || pid === undefined) return false;
    const ig = igAccounts();
    const found = ig.find((t) => t.display === username && t.pid === pid);
    return found === undefined ? false : true;
  };

  return (
    <Box pad="medium" background="light-2" width="500px">
      <FormField label="Facebook Account">
        <Select
          options={fbAccounts()}
          value={fbOptions}
          labelKey="display"
          valueLabel={fbOptions?.display}
          onChange={({ option }) => {
            setFbOptions(option);
          }}
        />
      </FormField>

      <FormField label="Facebook Business Page">
        <Select
          options={pageOptions}
          value={selectedPage}
          labelKey="name"
          valueLabel={selectedPage?.name}
          onChange={({ option }) => {
            setSelectedPage(option);
          }}
        />
      </FormField>

      <FormField label="Instagram Account">
        <TextInput disabled value={igAccount?.username || ''} />
      </FormField>

      {alreadyLinked(igAccount?.username, igAccount?.id) && (
        <Text color="status-critical">This account is already linked!</Text>
      )}
      <Button
        label="Link"
        onClick={async () => {
          const upgradeToken = await API.upgradeFbToken({
            token: fbOptions?.value,
          });
          const igToken = toDeserializedToken(
            Platforms.INSTAGRAM,
            upgradeToken?.data?.upgrade,
            igAccount?.username,
            igAccount?.id
          );
          const temp = tokens.concat(igToken);
          setTokens(temp);
          API.updateTokens(temp);
          setLocation(normalize(`${PATH.Instagram}/${igAccount?.id}`));
        }}
        disabled={alreadyLinked(igAccount?.username, igAccount?.id)}
      />
    </Box>
  );
}

export default AddInstagram;
