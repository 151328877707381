const Theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
  anchor: {
    fontWeight: 400,
  },
  tip: {
    content: {
      background: '#F8F8F8',
    },
  },
};

export default Theme;
