import { debug, error, log, toSerializedToken } from './Util';
import Collections from './Collections.json';
import Errors from './Errors.json';
import Platforms from './Platforms.json';
import dayjs from 'dayjs';

export default function API(firebase, featureFlags) {
  const db = firebase.firestore();

  // Cloud function
  const upgradeFbToken = firebase.functions().httpsCallable('upgradeFbToken');
  const generateCode = firebase.functions().httpsCallable('generateCode');
  const requestAccess = firebase.functions().httpsCallable('requestAccess');
  const redeemCode = firebase.functions().httpsCallable('redeemCode');

  async function getUser() {
    const user = firebase.auth().currentUser;
    if (!user || !user.uid) return null;
    return db.collection(Collections.USERS).doc(user.uid).get();
  }

  function providerToToken(provider, token = null) {
    if (!provider?.uid) return null;
    if (!provider?.providerId.toString().toLowerCase()) return null;

    switch (provider.providerId.toString().toLowerCase()) {
      case 'facebook.com':
        return toSerializedToken(
          Platforms.FACEBOOK,
          token,
          provider?.displayName,
          provider.uid,
          token !== null
        );
      default:
        return null;
    }
  }

  // @TODO: Add support for non-fb sso types
  async function createUser() {
    const user = firebase.auth().currentUser;
    debug('createUser', user);
    if (!user || !user.uid) return null;

    const { displayName, email, providerData } = user;
    let token;
    try {
      const tokenData = await firebase.auth().getRedirectResult();
      log('tokenData', tokenData);
      token = tokenData?.credential?.accessToken;
    } catch (e) {
      error(e);
      throw Errors.INVALID_TOKEN;
    }

    try {
      const upgradedToken = await upgradeFbToken({
        token,
      });
      const [fbProvider] = providerData;
      const doc = {
        displayName,
        email,
        verified: false,
        tokens: {
          ...providerToToken(fbProvider, upgradedToken?.data?.upgrade),
        },
      };
      log('doc to create', doc);
      db.collection(Collections.USERS).doc(user.uid).set(doc, { merge: true });
      return doc;
    } catch (e) {
      error(e);
      return;
    }
  }

  async function updateTokens(latestTokens = []) {
    const user = firebase.auth().currentUser;
    const pull = {};
    const tokens = latestTokens.reduce((acc, cur) => {
      pull[cur.platform] = pull[cur.platform] || false;
      pull[cur.platform] = cur.active || pull[cur.platform];

      return {
        ...acc,
        [`${cur.platform}-${cur.pid}`]: cur,
      };
    }, {});

    db.collection(Collections.USERS)
      .doc(user.uid)
      .set({ pull, tokens }, { merge: true });
  }

  async function createFeedback(data) {
    try {
      const user = firebase.auth().currentUser;
      if (!user || !user.uid) return null;

      const doc = {
        ...data,
        uid: user.uid,
        displayName: user.displayName || '',
        ts: dayjs().unix(),
        read: false,
      };

      log('feedback to create', doc);
      return db.collection(Collections.FEEDBACK).add(doc);
    } catch (e) {
      error(e);
      return;
    }
  }

  return {
    upgradeFbToken,
    generateCode,
    requestAccess,
    redeemCode,
    getUser,
    createUser,
    updateTokens,
    createFeedback,
  };
}
