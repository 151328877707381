import { Tabs, Tab } from 'grommet';
import React from 'react';
import AddInstagram from '../component/instagram/AddInstagram';
import AddFacebook from '../component/facebook/AddFacebook';

export default function Add({ tokens, setTokens }) {
  return (
    <Tabs>
      <Tab title="Instagram">
        <AddInstagram tokens={tokens} setTokens={setTokens} />
      </Tab>
      <Tab title="Facebook">
        <AddFacebook tokens={tokens} setTokens={setTokens} />
      </Tab>
    </Tabs>
  );
}
