import { Header as GHeader, Anchor, Menu, Nav, Box, Text } from 'grommet';
import { clear } from '../Storage';
import { PATH } from '../Util';
import { useLocation } from 'wouter';
import React from 'react';
import { useFirebase } from './Firebase';

function Header({ user, onLogout }) {
  const firebase = useFirebase();
  const [, setLocation] = useLocation();

  const versionLabel = (wrap) => (
    <Box direction="row" pad="small">
      {wrap}
      <Box
        background="status-unknown"
        pad={{ horizontal: 'xsmall' }}
        round
        margin={{ left: 'small' }}
        fill
      >
        <Text size="xsmall">Beta</Text>
      </Box>
    </Box>
  );

  return (
    <GHeader background="brand">
      {versionLabel(
        <Nav direction="row">
          <Anchor
            href={user ? PATH.Home : PATH.Login}
            label="measure.social"
            size="medium"
          />
        </Nav>
      )}
      {user && (
        <Menu
          margin={{ right: 'xsmall' }}
          label={user.displayName}
          items={[
            {
              label: 'Settings',
              href: PATH.Settings,
            },
            {
              label: 'Add',
              href: PATH.Add,
            },
            ...[],
            {
              label: 'Admin',
              href: PATH.Admin,
            },
            {
              label: 'Logout',
              onClick: async () => {
                clear();
                await firebase.auth().signOut();
                onLogout?.();
                setLocation(PATH.Login);
              },
            },
          ]}
        />
      )}
    </GHeader>
  );
}

export default Header;
