import React, { useEffect, useState } from 'react';
import { Box, Select } from 'grommet';
import StaticMetric from '../StaticMetric';
import Facebook from '../../Facebook';
import { error, log, IG, debug } from '../../Util';
import Spinner from '../Spinner';

const { getPostObjLabel } = IG;

function PostTags({ token }) {
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState();
  const [tagUserFollowers, setTagUserFollowers] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  // pull initial tags, dont watch tags because they change here.
  const TAGS_FIELDS = 'caption,username,id,like_count,comments_count,timestamp';
  useEffect(() => {
    const getTags = async () => {
      try {
        if (!token || !token?.pid) return;
        let res = await Facebook.getIgFields(
          token?.value,
          `/${token?.pid}/tags`,
          TAGS_FIELDS,
          50,
          false,
          [],
          {},
          (data) => data.filter((i) => i.caption.includes(token.display))
        );
        debug('tags', res);
        setTags(() => res);
        setLoading(() => false);
      } catch (e) {
        error(e);
        setLoading(() => false);
      }
    };

    getTags();
  }, [token, token?.pid, token?.value]);

  // on selected tag change, pull deep post info
  useEffect(() => {
    const queryTag = async () => {
      setLoading(() => true);
      if (!selectedTag || !selectedTag?.id) return;
      let [data] = await Facebook.getIgFields(
        token?.value,
        `/${token?.pid}`,
        `mentioned_media.media_id(${selectedTag?.id}){comments{text,username,timestamp},comments_count,like_count}`
      );

      setLoading(() => false);
      debug('MENIONTED', selectedTag, data);
    };

    queryTag();
  }, [selectedTag, token?.pid, token?.value]);

  const getTotalFollowersForUser = async (username) => {
    try {
      let [data] = await Facebook.getIgFields(
        token?.value,
        `/${token?.pid}`,
        `business_discovery.username(${username}){followers_count}`
      );
      let { business_discovery } = data;
      let { followers_count } = business_discovery;
      setTagUserFollowers(followers_count);
      log('getTotalFollowersForUser', data, followers_count);
    } catch (e) {
      error(e);
      setTagUserFollowers(0);
      setErrorMessage(
        'The selected post was not created by a business account. Analytics are not available.'
      );
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Box direction="column" pad="small" gap="small" flex>
      <Box direction="column" pad="small">
        {errorMessage ?? ''}
        <Select
          size="medium"
          options={tags}
          placeholder="Select a tagged post"
          value={selectedTag}
          labelKey={getPostObjLabel}
          valueKey={getPostObjLabel}
          onChange={async ({ option }) => {
            setErrorMessage(null);
            setSelectedTag(option);
            await getTotalFollowersForUser(option?.username);
          }}
        />
      </Box>
      <Box direction="row" pad="small" gap="small" fill>
        <StaticMetric
          label="Comments"
          data={[
            {
              value: selectedTag?.comments_count || 0,
            },
          ]}
        />
        <StaticMetric
          label="Likes"
          data={[
            {
              value: selectedTag?.like_count || 0,
            },
          ]}
        />
        <StaticMetric
          label="Engagement"
          data={[
            {
              value: (
                ((selectedTag?.comments_count + selectedTag?.like_count) /
                  tagUserFollowers) *
                  100 || 0
              ).toFixed(2),
              unit: '%',
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default PostTags;
